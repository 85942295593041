.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.CommandBar {
  float:right;
  width: 42px;
  padding: 5px;
}

.inputButton {
  width: 32px;
  height: 32px;
}

.delayDisplay {
  color: lightcyan;
}